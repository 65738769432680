

export default function shortNumber(num) {
    let t = "";
    if (num >= 1000) {
        num = (num / 1000).toFixed(1);
        t = "k";
        if (num >= 10) {
            num = (num / 10).toFixed(1);
            t = "w";
            if (num >= 100) {
                num = (num / 100).toFixed(1);
                t = "M";
                if (num >= 1000) {
                    num = (num / 1000).toFixed(1);
                    t = "G";
                }
            }
        }
    } 
    return parseFloat(num) + t;
}
