/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import PropTypes from 'prop-types';

import Link from 'next/link';
import Image from 'next/image';

import { 
    Button,
    Tooltip,
    Card,
    Text
} from '@nextui-org/react';
import styles from './project-card-column.module.scss';
import getConfig from 'next/config';
import Avatar from '../../Core/avatar/avatar';

import { 
    Show,
    TimeCircle,
    Edit,
    Setting,
    Delete
} from 'react-iconly';
import dayjs from 'dayjs';
import classNames from 'classnames';
import shortNumber from 'src/utils/short-number';

const {publicRuntimeConfig} = getConfig();

const ProjectCardColumn = ({
    onClickSettings,
    projectInfo = {
        id: 1,
        updated_at: '2022-01-28T10:19:46.972+08:00',
        plays: 0,
        favorites: 0,
        name: 'Example Project',
        user_name: 'SinanGentoo'
    },
    optimizedImage = false,
    showAuthor,
    canEdit,
    activated,
    onDelete,
    onClick
}) => {
    return (
        <Card
            shadow={false}
            clickable
            className={classNames(styles.overlay, {
                [styles.activated]: activated
            })}
        >
            <div className={styles.content}>
                {onClick ? (
                    <a className={styles.projectImage} onClick={() => {
                        onClick(projectInfo.id, activated);
                    }}>
                        <Image
                            priority
                            unoptimized={!optimizedImage}
                            src={`${publicRuntimeConfig.backend}project/thumbnail?id=${projectInfo.id}`}
                            alt={projectInfo.title}
                            layout="fixed"
                            objectFit="cover"
                            width="100%"
                            height="1px"
                            className={styles.image}
                            draggable={false}
                        />
                    </a>
                ) : (
                    ( <Link href={`/project/${projectInfo.id}`}>
                        <a className={styles.projectImage} target="_blank">
                            <Image
                                priority
                                unoptimized={!optimizedImage}
                                src={`${publicRuntimeConfig.backend}project/thumbnail?id=${projectInfo.id}`}
                                alt={projectInfo.name}
                                layout="fixed"
                                objectFit="cover"
                                width="100%"
                                height="1px"
                                className={styles.image}
                                draggable={false}
                            />
                        </a>
                    </Link>)
                )}

                <div className={styles.projectInfo}>
                    <div>
                        {onClick ? (
                            <a className={styles.projectName} onClick={() => {
                                onClick(projectInfo.id, activated);
                            }}>
                                {projectInfo.name?.trim()}
                            </a>
                        ) : (
                            (<Link
                                href={`/project/${projectInfo.id}`}  
                                passHref
                            >

                                <a target="_blank" className={styles.projectName}>{projectInfo.name?.trim()}</a>

                            </Link>)
                        )}
                        <div className={styles.projectStatus}>
                            <div className={styles.time} title={dayjs(projectInfo.content_update_at).format('LLLL')}>
                                <Tooltip content="修改时间" rounded>
                                    <TimeCircle set="broken" size="small"/> {dayjs(projectInfo.content_update_at).fromNow()}
                                </Tooltip>
                            </div>
                            <div className={styles.views}>
                                <Tooltip content="观看量" rounded>
                                    <span className={styles.view}><Show set="broken" size="small"/> {shortNumber(projectInfo.plays)}</span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    {showAuthor && (
                        <div className={styles.projectAvatar}>
                            <div className={styles.avatar}>
                                <Avatar 
                                    size={25}
                                    avatar={projectInfo.user_avatar}
                                    name={projectInfo.user_name}
                                />
                            </div>
                            <div className={styles.authorInfo}>
                                <Link href={`/user/${projectInfo.user}`} passHref>
                                    <a target="_blank">
                                        {projectInfo.user_name}
                                    </a>
                                </Link>
                            </div>
                        </div>
                    )}

                    {canEdit && (
                        <div className={styles.projectAction}>
                            <Button.Group size="sm" bordered>
                                <Button
                                    title='设置'
                                    auto
                                    onClick={() => onClickSettings(projectInfo.id)}
                                >
                                    <Setting />
                                    {/* <Text size={12} color="primary" className={styles.actionText}>设置</Text> */}
                                </Button>
                                <Button
                                    title='删除'
                                    auto
                                    onClick={() => onDelete(projectInfo)}
                                >
                                    <Delete />
                                    {/* <Text size={12} color="primary" className={styles.actionText}>删除</Text> */}
                                </Button>
                            </Button.Group>
                        </div>
                    )}
                                    
                </div>
            </div>       
        </Card>
    );
};


ProjectCardColumn.propTypes = {
    projectInfo: PropTypes.object.isRequired,
    optimizedImage: PropTypes.bool
};

export default ProjectCardColumn;