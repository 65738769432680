/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import PropTypes from 'prop-types';

import Link from 'next/link';
import Image from 'next/image';

import { Card, Tooltip } from '@nextui-org/react';
import { 
    Show,
    TimeCircle,
    Edit,
    Setting,
    Delete
} from 'react-iconly';
import getConfig from 'next/config';
import styles from './project-card.module.scss';

import dayjs from 'dayjs';

import Avatar from '@/components/Core/avatar/avatar.jsx';
import shortNumber from 'src/utils/short-number';

const {publicRuntimeConfig} = getConfig();

function ProjectCard({
    projectInfo  = {
        id: 1,
        updated_at: '1926-08-17T10:19:46.972+08:00',
        plays: 0,
        favorites: 0,
        name: 'Example Project',
        user_name: 'SinanGentoo'
    },
    noShadow,
    showAuthor,
    optimizedImage = false
}) {
    return (
        <div className={styles.overlay}>

            <Card
                clickable
                className={styles.card}
                bordered={noShadow}
                shadow={!noShadow}
            >
                <div className={styles.projectImage}>
                    <a href={`/project/${projectInfo.id}`} target="_blank">
                        <Image
                            priority
                            unoptimized={!optimizedImage}
                            src={`${publicRuntimeConfig.backend}project/thumbnail?id=${projectInfo.id}&t=${new Date(projectInfo.content_update_at).getTime()}`}
                            alt={projectInfo.name}
                            // layout="raw"
                            placeholder='blur'
                            blurDataURL={`${publicRuntimeConfig.basePath}/img/project-default-preview-img.png`}
                            width="480"
                            height="360"
                            className={styles.image}
                            draggable={false}
                        />
                    </a>
                    { projectInfo.is_union &&
                            (<div className={styles.tag}>
                                <div className={styles.unionContainer}>合作</div>
                            </div>)
                    }
                    { projectInfo.is_remix &&
                            (<div className={styles.tag}>
                                <div className={styles.remixContainer}>改编</div>
                            </div>)
                    }
                </div>
                { showAuthor ? (
                    <div className={styles.projectStatus}>
                        <div className={styles.avatar}>
                            <Avatar 
                                size={40}
                                passHref
                                user={projectInfo.user}
                                name={projectInfo.user_name}
                                avatar={projectInfo.user_avatar}
                            />
                        </div>
                        <div className={styles.projectInfo}>
                            <div className={styles.projectName}>
                                <a href={`/project/${projectInfo.id}`} target="_blank">{projectInfo.name.trim()}</a>
                            </div>
                            <div className={styles.authorInfo}>
                                <a href={`/user/${projectInfo.user}`} target="_blank">{projectInfo.user_name}</a>
                            </div>
                        </div>
                    </div>) : (
                    <div className={styles.projectStatus}>
                        <div className={styles.projectInfoOnly}>
                            <div className={styles.projectName}>{projectInfo.name.trim()}</div>
                            <div className={styles.projectStatusNoAuthor}>
                                <div className={styles.time} title={dayjs(projectInfo.content_update_at).format('LLLL')}>
                                    <Tooltip content="修改时间" rounded>
                                        <TimeCircle set="broken" size="small"/> {dayjs(projectInfo.content_update_at).fromNow()}
                                    </Tooltip>
                                </div>
                                <div className={styles.views}>
                                    <Tooltip content="观看量" rounded>
                                        <span className={styles.view}><Show set="broken" size="small"/> {shortNumber(projectInfo.plays)}</span>
                                    </Tooltip>
                                </div>
                            </div>                                     
                        </div>
                    </div>
                )}
            </Card>
        </div>
    );

}

ProjectCard.propTypes = {
    projectInfo: PropTypes.object.isRequired,
    optimizedImage: PropTypes.bool
};


export default ProjectCard;