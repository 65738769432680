import * as React from 'react';
import PropTypes from 'prop-types';

import { Backpack } from 'react-kawaii';
import { 
    Loading,
    Spacer,
} from '@nextui-org/react';
import {FormattedMessage} from 'react-intl';
import ProjectCard from '../project-card/project-card';
import classNames from 'classnames';
import styles from './project-list.module.scss';
import ProjectCardColumn from '../project-card/project-card-column';

function ProjectList({
    children, 
    projectData, 
    isLoading, 
    optimizedImage,
    column,
    grid,
    onClick,
    activated,
    noShadow,
    showAuthor,
    canEdit,
    onClickSettings,
    onDelete
}) {
    return (
        <div className={styles.overlay}>
            <div className={styles.header}>{children}</div>
            <Spacer y={0.5} />
            <div className={classNames(styles.list,{
                [styles.loading]: isLoading,
                [styles.column]: column,
                [styles.empty]: projectData.length === 0 && !isLoading
            })}>
                {isLoading ? 
                    <Loading /> :
                    projectData.length === 0 ? (
                        <div>
                            <Backpack size={100} mood="shocked" color="#FFD882"/>
                            <p className={styles.dark}>
                                <FormattedMessage 
                                    id='projectlist.empty' 
                                    defaultMessage='小别针的背包还有点空'
                                /></p>
                            <p className={styles.dark}>
                                <FormattedMessage 
                                    id='projectlist.fill' 
                                    defaultMessage="快点去填补这里的空白吧" 
                                /></p>
                        </div>
                    ) : (
                        <div className={classNames(styles.grid, {
                            [styles.gridColumn]: column,
                            [styles.gridOne]: grid === 1,
                            [styles.gridTwo]: grid === 2,
                            [styles.gridThree]: grid === 3,
                            [styles.gridFour]: grid === 4
                        })}>
                            {projectData.map(project => {
                                if (column) {
                                    return (
                                        <ProjectCardColumn 
                                            key={project.id} 
                                            optimizedImage={optimizedImage}
                                            projectInfo={project} 
                                            showAuthor={showAuthor}
                                            activated={activated && activated.includes(project.id)}
                                            canEdit={canEdit}
                                            onClick={onClick}
                                            onDelete={onDelete}
                                            onClickSettings={onClickSettings}
                                            noShadow={noShadow}
                                        />
                                    );
                                } else {
                                    return (
                                        <ProjectCard 
                                            key={project.id} 
                                            optimizedImage={optimizedImage}
                                            projectInfo={project} 
                                            noShadow={noShadow}
                                            showAuthor={showAuthor}
                                        />
                                    );
                                }
                            })}
                        </div>
                    )}
            </div>
        </div>
    );
}

ProjectList.propTypes = {
    children: PropTypes.node,
    projectData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

ProjectList.defaultProps = {
    isLoading: true,
    showAuthor: true,
    projectData: []
};

export default ProjectList;